import {MenuCreateOptionInterface} from "kernel/types/module";
// @ts-ignore
import siteConfig from '@/config/site.js'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      adminTop: [
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_MAINTAINER']),
          label: 'Debug',
          icon: 'fa fa-bug',
          group: [
            {
              label: 'Log Viewer',
              route: { name: 'admin.log-viewer' },
              blank: true,
            },
            {
              label: 'Mail Template',
              route: {
                name: 'admin.mail-template',
              },
            },
            {
              label: 'Html Editor',
              route: {
                name: 'admin.html-editor',
              },
            },
            {
              label: 'Sitemap',
              route: {
                name: 'admin.sitemap',
              },
            },
            {
              label: 'SMS Debug',
              route: { name: 'sms-debug' },
            },
          ],
        },
      ],
      admin: [
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
          label: this.$t('report'),
          icon: 'fa fa-images',
          route: {
            name: 'report',
          },
        },
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
          label: this.$t('report_cloud'),
          icon: 'fa fa-images',
          route: {
            name: 'report.cloud',
          },
        },
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
          label: this.$t('photo_manager'),
          icon: 'fa fa-images',
          route: {
            name: 'photo',
          },
        },
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
          label: this.$t('file_manager'),
          icon: 'fa fa-folder-open',
          route: {
            name: 'file',
          },
        },
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
          label: this.$t('page.otp'),
          icon: 'fa fa-key',
          route: {
            name: 'otp',
          },
        },
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
          label: this.$t('page.app-config.linerp'),
          icon: 'fab fa-line',
          route: {
            name: 'app-config-linerp',
          },
        },
      ],
    }
  }
}

export default () => (new menu()).get()
